.transaction-page-div {
    display: flex;
    flex-wrap: wrap;
    height: calc(100vh - 80px);
    overflow-y: scroll;
    width: 100%;
    color: #2F3033;
    position: relative;
}

.transaction-page {
    margin-top: 3.26rem;
    margin-left: auto;
    margin-right: auto;
    flex-grow: 1;
    padding: 0px 8%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: calc(100% - 317px);
    max-width: calc(960px + 16%);
}

.transaction-tag {
    width: 160px;
    min-height: 32px;
    max-height: 32px;
    height: 32px;
    border-radius: 32px;
    border: 0.5px solid #55585E;
}

.transaction-button {
    border: unset;
    cursor: pointer;
    background-color: white;
    color: #55585E;
    padding: 6px 12px;
    width: 80px;
    min-width: 80px;
    min-height: 32px;
    max-height: 32px;
    height: 32px;
    border-radius: 32px;
}

.transaction-active {
    background-color: #55585E;
    box-shadow: 0 0 0 0.5px #55585E;
    color: white;
}

.transaction-button.transaction-active:hover {
    background-color: #6B6E76;
}

.transaction-body-div {
    width: 100%;
    margin-top: 32px;
}