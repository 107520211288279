.payform-div {
    flex-grow: 1;
    padding: 0px 8% 2em;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 16px;
    width: calc(100% - 317px);
}

.payform-head {
    font-size: 20px;
    font-weight: bold;
    line-height: 1.33;
    margin: 2em 0;
}

.payform-form {
    width: min(100%, 443px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 16px;
}

.payform-input-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 8px;
    position: relative;
    overflow-x:visible;
    box-sizing: border-box;
}

.amount-input-div.payform-amount {
    border-color: transparent;
    display: flex;
    justify-content: flex-start;
}

.payform-note-input-div.hasPayErrors,
.amount-input-div.payform-amount.hasPayErrors {
    border-color:#C72830;
    position: relative;
}

.amount-input-div.payform-amount b {
    font-size: 1.5rem;
    padding-top: 8px;
    padding-right: 8px;
}

.amount-input-div.payform-amount input {
    font-size: 3.25rem;
    font-weight: 500;
    padding: 0;
    margin: 0;
}

.amount-input-div.payform-amount.hasPayErrors b,
.amount-input-div.payform-amount.hasPayErrors input {
    color: rgb(199, 40, 48);
}

.hasPayErrors {
    border-color: #C72830;
    color: #C72830;
    background-color: #FCF4F5;
}

.payform-input-div {
    max-width: min(443px, 100%);
    width: 100%;
    padding: 8px;
    box-sizing: border-box;
    border-radius: 8px;
    border: 0.5px solid #888C94;
    min-height: 54px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    column-gap: 8px;
    row-gap: 8px;
}

.payform-note-input-div:focus-within,
.payform-input-div:focus-within {
    border-color: #0074DE;
}

.payform-input-div label {
    color: #888c94;
    font-size: inherit;
}

.payform-note-input-div textarea,
.payform-input-div input {
    flex-grow: 1;
    color: rgb(47, 48, 51);
    border: unset;
    background-color: transparent;
    font-size: inherit;
    padding: 8px 0;
    resize: none;
    font-family: inherit;
}

.payform-note-input-div textarea:focus-visible,
.payform-input-div input:focus-visible {
    outline: unset;
}

.payform-note-input-div.hasPayErrors:focus-within,
.payform-input-div.hasPayErrors:focus-within {
    border-color: #C72830;
}

.payform-input-div.hasPayErrors {
    border-color: #C72830;
    color: #C72830;
    background-color: #FCF4F5;
}

.all-users-drop-down-div {
    position: relative;
    z-index: 1;
    border-radius: 8px;
    box-shadow: 0px 0px 8px rgb(0, 0, 0, 50%);
    background-color: white;
    height: max(350px, fit-content);
    max-height: 350px;
    width: 443px;
    max-width: min(100%, 443px);
    box-sizing: border-box;
}

.payform-note-input-div {
    width: 100%;
    border: 0.5px solid #888C94;
    border-radius: 8px;
    box-sizing: border-box;
    height: 113px;
    padding: 8px;
    display: flex;
    flex-direction: column;
    row-gap: 8px;
    color: #888c94;
    font-size: inherit;
}

.auth-error-div.payform-error {
    width: 100% !important;
    padding-left: 8px;
    box-sizing: border-box;
}

.fa-solid.fa-exclamation.payErrorIcon {
    display: inline-block;
    padding: 8px;
    color: #C72830;
    font-size: 28px;
    font-weight: 900;
}

.payform-note-icon {
    position: absolute;
    right: 0;
    top:0;
}

.payform-button-div {
    display: flex;
    flex-wrap: wrap;
    column-gap: 8px;
    box-sizing: border-box;
    width: min(100%, 443px);
    margin-top: 48px;
}

.payform-div button {
    cursor: pointer;
    flex-grow: 1;
    width: calc(50% - 4px);
    box-sizing: border-box;
    align-items: center;
    background-color: #0074DE;
    padding: 12px 24px;
    border: unset;
    border-radius: 4rem;
    color: white;
}

.payform-div button:hover {
    background-image: linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1))
}

.payform-username-bubble {
    border-radius: 4px;
    padding: 8px 12px;
    background-color:#55585E;
    color: white;
    font-size: 0.9rem;
    display: flex;
    flex-wrap: wrap;
    column-gap: 4px;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}

.payform-username-bubble span {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    max-width: 100%
}

.payform-username-bubble .fa-solid.fa-x {
    font-size: 0.75rem;
}

.payform-total {
    font-size: 14px;
}

/* #888C94 */