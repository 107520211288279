.transaction-top {
    display: flex;
    align-items: flex-start;
    column-gap: 8px;
    margin-bottom: 24px;
}

.transaction-profile-pic {
    max-width: 46px;
    min-width: 46px;
    width: 46px;
    max-height: 46px;
    min-height: 46px;
    height: 46px;
    border-radius: 50%;
    flex-basis: fit-content;
}

.transaction-info-div {
    flex-basis: auto;
    flex-grow: 1;
    flex-shrink: 1;
    display: flex;
    flex-direction: column;
    row-gap: 6px;
    font-size: 16px;
    overflow: hidden;
}

.transaction-info-div span {
    overflow-wrap: anywhere;
}

.transaction-amount {
    flex-basis: fit-content;
    white-space: nowrap;
}

.transaction-amount.outflow {
    color: #C72830;
}

.transaction-amount.inflow {
    color: #148572;
}