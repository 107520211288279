.friend-page-div {
    display: flex;
    flex-wrap: wrap;
    height: calc(100vh - 80px);
    overflow-y: scroll;
    width: 100%;
    color: #2F3033;
    position: relative;
}

.friend-page {
    margin-top: 3.26rem;
    margin-left: auto;
    margin-right: auto;
    flex-grow: 1;
    padding: 0px 8%;
    box-sizing: border-box;
    width: calc(100% - 317px);
    max-width: calc(960px + 16%);
}

.friend-head {
    font-size: 20px;
    font-weight: bold;
    line-height: 1.33;
    margin: 0.67em 0;
}

.friend-tag {
    box-sizing: border-box;
    width: 100%;
    display: flex;
    font-size: 1.125rem;
    font-weight: 400;
    line-height: 1.33;
}

.friend-button {
    border: unset;
    cursor: pointer;
    padding: 6px 12px;
    background-color: white;
    width: 50%;
    color: #6B6E76;
    min-height: 32px;
    font-size: 0.875rem;
    font-weight: 600;
    margin-bottom: 16px;
}

.friend-active {
    color: #0074DE;
    border-bottom: 2px solid #0074DE;
}

.friend-single-div {
    margin-top: 16px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    gap: 8px;
}

.friend-profile-pic {
    max-width: 46px;
    min-width: 46px;
    width: 46px;
    max-height: 46px;
    min-height: 46px;
    height: 46px;
    border-radius: 50%;
    flex-basis: fit-content;
}

.friend-info-div {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    row-gap: 4px;
    overflow: hidden;
}

.friend-info-div span {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden
}

.friend-email {
    color: #0074DE;
}

.accept-friend-button,
.unfriend-button {
    cursor: pointer;
    background-color: white;
    border: 1px solid #2F3033;
    border-radius: 4rem;
    min-width: 140px;
    max-width: 140px;
    width: 140px;
    box-sizing: border-box;
    padding: 8px 16px;
    font-size: 1rem;
    line-height: 1.25;
}

.accept-friend-button:hover,
.unfriend-button:hover {
    background-color: #2F3033;
    color: white;
}

.friend-divider {
    margin-top: 16px;
    margin-bottom: 16px;
    border-top: 1px solid #ccc;
}