.auth-div {
    box-sizing: border-box;
    height: calc(100vh - 80px);
    padding: 20px max(10vw, 8px);
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    overflow-y: auto;
}

.auth-sub-div {
    box-sizing: border-box;
    outline: 1px solid #c8d1e0;
    border-radius: 8px;
    width: 470px;
    padding: 40px 56px 86px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.login-page-mid-span {
    font-size: 18px;
    margin-top: 48px;
    margin-bottom: 16px;
}

.auth-form {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 16px;
}

.auth-form-error-input-wrapper {
    width: 100%;
}

.auth-form-input-wrapper {
    width: 100%;
    box-sizing: border-box;
    border: 0.5px solid #888C94;
    border-radius: 8px;
    height: 56px;
    padding: 8px 12px 8px;
    display: flex;
    row-gap: 4px;
    position: relative;
    font-size: 14px;
}

.auth-form-label-input-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.auth-form-input-wrapper:focus-within {
    border: 0.5px solid #0074DE;
}

.auth-label {
    color: rgba(0, 0, 0, 0.54);
    font-weight: 400;
    font-size: 12px;
}

.auth-form-input-wrapper input {
    border: unset;
    padding: 0px;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.54);
}

.hasInput {
    color: #0074DE;
}

.auth-form-input-wrapper input:focus-visible {
    outline: unset;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0px 1000px #ffffff inset !important;
}

.login-button {
    cursor: pointer;
    width: 100%;
    background-color: #0074DE;
    border: 1px solid #0074DE;
    border-radius: 4rem;
    padding: 0.8rem 1.5rem;
    font-size: 14px;
    color: white;
    margin-top: 16px;
}

.sign-up-a.log-in-page {
    width: 100%;
    box-sizing: border-box;
    color: #0074DE;
    border-color: #0074DE;
    padding: 0.8rem 1.5rem;
    font-size: 14px;
}

.auth-error-div {
    color: rgb(199, 40, 48);
    font-size: 0.75rem;
    font-weight: 400;
    padding: 6px 8px;
}

.hasErrors {
    border-color: #C72830;
    color: #C72830;
    background-color: #FCF4F5;
}

.hasErrors input {
    background-color: #FCF4F5;
}

.hasErrors label {
    color: #C72830;
}

.hasErrors .fa-solid.fa-exclamation {
    display: block;
    padding: 8px;
}

.fa-solid.fa-exclamation {
    display: none;
    font-size: 16px;
}

.logout-butt {
    cursor: pointer;
    border: unset;
    background: unset;
    color: #2F3033;
    font-size: 1rem;
    font-weight: 400;
    text-align: left;
    padding: 0px;
    margin: 0px;
}

.logout-butt:hover {
    color: #0074DE;
}