.profile-image-upload-modal {
    background-color: white;
    width: min(400px, 90%);
    min-width: 400px;
    max-width: 400px;
    height: min(240px, 50%);
    min-height: 240px;
    max-height: 240px;
    border-radius: 1rem;
    padding: 1rem;
}

#image-add-photo {
    display: none;
}

.profile-image-upload-modal form,
.profile-image-upload-modal,
.profile-image-preview-div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.drag-and-drap-wrapper,
.profile-image-preview-div {
    border-radius: 50%;
    width: 78px;
    min-width: 78px;
    max-height: 78px;
    height: 78px;
    min-height: 78px;
    max-height: 78px;
}

.drag-and-drap-wrapper {
    margin: 1rem 0;
    border: 1px dashed rgb(176, 176, 176);
    display: flex;
    justify-content: center;
    align-items: center;
}

.drag-drop-title {
    font-size: 22px;
    font-weight: 500;
    margin-top: 1rem;
    margin-bottom: 0.5rem;
}

.image-input-button {
    margin-top: 1.5rem;
    cursor: pointer;
    border: 1px solid rgb(34, 34, 34);
    border-radius: 0.5rem;
    background: white;
    padding: 12px;
    font-size: inherit;
    font-family: inherit;
    color: inherit;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
}

.image-input-button:hover {
    background-color: #f7f7f7;
    color: black;
    border-radius: black;
}

.profile-image-upload-button-div button {
    cursor: pointer;
    background-color: white;
    border: 1px solid #2F3033;
    border-radius: 4rem;
    min-width: 120px;
    max-width: 140px;
    width: 140px;
    box-sizing: border-box;
    padding: 10px 16px;
    font-size: 1rem;
    line-height: 1.25;
}

.profile-image-upload-loading-span {
    padding-top: 0.5rem;
}