html,
body,
#root {
    font-family: "Ubuntu";
    margin: 0px;
    padding: 0px;
    height: 100vh;
    width: 100vw;
    box-sizing: border-box;
}

#root {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
}

a {
    text-decoration: none;
    color: inherit;
}

a:focus-visible {
    outline: unset;
}

a:active {
    color: inherit;
}