.sidebar-div {
    width: 317px;
    min-width: 317px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    row-gap: 2rem;
    padding: 1.7rem 8px 1.7rem 40px;
    border-right: 1px solid #ccc;
    box-sizing: border-box;
    height: calc(100vh - 80px);
    overflow-y: auto;
    position: sticky;
    top: 0;
}

.side-bar-profile-pic {
    width: 78px;
    height: 78px;
    border-radius: 50%;
}

.sidebar-single-div.profile-container {
    width: 100%;
    display: flex;
    align-items: center;
    column-gap: 8px;
}

.sidebar-name-email-div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    row-gap: 8px;
    height: 100%;
    width: calc(100% - 78px - 8px);
}

.sidebar-mobile-name-email-div span,
.sidebar-name-email-div span {
    font-size: 1.5rem;
    font-weight: 500;
    line-height: 1.33;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden
}

.sidebar-mobile-name-email-div a,
.sidebar-name-email-div a {
    color: #0074DE;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden
}

.sidebar-balance {
    color: #2F3033;
    font-size: 1rem;
    font-weight: 400;
}

.sidebar-single-div.sidebar-menu {
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    row-gap: 24px;
    color: #2F3033;
    font-size: 1rem;
    font-weight: 400;
}

.sidebar-single-div.sidebar-menu a:hover {
    color: #0074DE;
}

.sidebar-notification-div {
    width: 100%;
    display: flex;
    align-items: center;
}

.sidebar-notification-div div {
    flex-grow: 1;
}

.sidebar-notification-div span {
    background-color: #c72830;
    color: white;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.75rem;
}

.hasUnread {
    font-weight: 800;
}


@media (max-width: 960px) {
    .sidebar-div {
        display: none !important;
    }
}

.profile-add-image {
    cursor: pointer;
}