.sidebar-mobile-wrapper {
    display: none;
}

.sidebar-menu-icon-wrapper {
    margin-top: 1.5rem;
    margin-left: 1rem;
}

.sidebar-mobile-head {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.sidebar-mobile-head a {
    width: unset;
    flex-grow: 1;
    justify-content: flex-start;
}

.show-menu-icon {
    display: block;
}

.no-menu-icon {
    display: none;
}

.sidebar-mobile-exit-button {
    cursor: pointer;
    background-color: white;
    border: unset;
    border-radius: 50%;
    width: 24px;
    height: 24px;
}

.sidebar-mobile-exit-button:hover {
    color: #6b6e76
}

.sidebar-mobile-exit-button i,
.sidebar-menu-icon-wrapper i {
    font-size: 1.25rem;
    color: #888c94;
}

.close-sidebar-mobile {
    display: none;
}

.open-sidebar-mobile {
    position: absolute;
    display: flex;
}

.sidebar-mobile-div {
    background-color: white;
    z-index: 2;
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    row-gap: 2rem;
    padding: 1.7rem 8px 1.7rem 40px;
    box-sizing: border-box;
    height: calc(100vh - 80px);
    overflow-y: auto;
}

.side-bar-profile-pic {
    width: 78px;
    height: 78px;
    border-radius: 50%;
}

.sidebar-mobile-single-div.profile-container {
    display: flex;
    align-items: center;
    column-gap: 8px;
    width: 100%;
}

.sidebar-mobile-name-email-div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    row-gap: 8px;
    height: 100%;
    width: calc(100% - 78px - 8px);
}

.sidebar-mobile-name-email-div span {
    font-size: 1.5rem;
    font-weight: 500;
    line-height: 1.33;
}

.sidebar-mobile-name-email-div a {
    color: #0074DE;
}

.sidebar-mobile-balance {
    color: #2F3033;
    font-size: 1rem;
    font-weight: 400;
}

.sidebar-mobile-single-div.sidebar-mobile-menu {
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    row-gap: 24px;
    color: #2F3033;
    font-size: 1rem;
    font-weight: 400;
}

.sidebar-mobile-single-div.sidebar-mobile-menu a:hover {
    color: #0074DE;
}

@media (max-width: 960px) {
    .openrequest-page-div,
    .friend-page-div,
    .transaction-page-div,
    .pay-page-div {
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap !important;
    }
    
    .sidebar-mobile-wrapper {
        display: block !important;
    }

    .friend-page,
    .openrequest-page,
    .payform-div,
    .transaction-page {
        width: 100% !important;
    }

}