.view-comments-wrapper {
    margin-left: 54px;
}

.delete-comment-button {
    cursor: pointer;
    border: unset;
    background-color: white;
    color: rgb(0, 116, 222);
    font-size: 0.85rem;
    font-weight: 600;
}