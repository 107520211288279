.home-main-div {
    padding: 6rem 5rem;
    box-sizing: border-box;
    width: 100%;
    height: calc(100vh - 160px);
}

.home-main-sub-div {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    box-sizing: border-box;
    column-gap: 80px;
    margin: 0px auto;
    width: 100%;
}

.home-main-left {
    padding: 8rem 0;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: flex-start;
    row-gap: 2rem;
}

.home-main-head {
    font-size: 3rem;
    max-width: min(300px, 20rem);
}

.home-main-narrative {
    font-size: 1rem;
    color: rgb(47, 48, 51);
    max-width: min(400px, 40rem);
}

.demo-user {
    cursor: pointer;
    padding: 0.6rem 1.5rem;
    width: 120px;
    border: 1px solid rgb(0, 140, 255);
    background-color: rgb(0, 140, 255);
    color: white;
    border-radius: 4rem;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    column-gap: 0.4rem;
    box-shadow: 6px 8px rgba(0, 215, 145, 0.7);
}

.vinmo-span-in-main {
    font-weight: bold;
    font-size: 18px;
}

.home-main-right {
    /* outline: 1px solid blue; */
    position: relative;
    overflow: visible;
}

.doodle-green {
    position: absolute;
    z-index: 2;
    width: 55px;
    top: 80px;
    left: 80px;
}

.doodle-multi {
    position: absolute;
    z-index: 2;
    width: 90px;
    bottom: 20%;
    left: 52%;
}

.upper-phone {
    z-index: 1;
    width: 24rem;
}

.upper-phone-shadow {
    position: absolute;
    z-index: -1;
    width: 100%;
    left: 0px;
    top: 0px;
    overflow: unset;
}


@media (max-width: 800px) {
    .home-main-right {
        display: none;
    }
}

@media (max-width: 680px) {
    .home-main-div {
        padding: 0px 16px;
    }
}